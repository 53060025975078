import React from "react";
import styled from "styled-components";
import { colors } from "../../../styles/colors";
import numberWithCommas from "../../../util/numberWithCommas";
import BarChart from "../../DataPlatform/Charts/BarChart";
import PieChart from "../../DataPlatform/Charts/PieChart";
import PieDonutChart from "../../DataPlatform/Charts/PieDonutChart";
import ETAPGraphs from "./ETAPGraphs";
// import PieChart from "../../components/Dashboard/PieChart";
// import BarChart from "../../components/Dashboard/BarChart";

const MapStatsWrapper = styled.div`
  font-family: "Lato";
  color: #536275;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const MapStatsTitle = styled.h2`
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 0px;
  margin-top: -50px;
  width: auto;
  background-color: white;
  z-index: 1200;
  padding: 19px;
  margin-left: -20px;
  width: 300px;

  @media only screen and (max-width: 991px) {
    margin: 0px;
    padding-left: 0px;
  }
  @media only screen and (max-width: 478px) {
    width: auto;
  }
`;

const MapStatsTotals = styled.div`
  display: flex;
  flex-direction: column;
`;

const TotalLabel = styled.span`
  font-weight: 700;
  margin: 10px 0px;
`;

const Total = styled.span`
  color: ${colors.orange};
`;

const MapStatsGraphs = styled.div`
  display: flex;
  flex-direction: row;
`;

const Chart = styled.div`
  margin-left: ${({ left }) => (left ? "20px" : "0px")};

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;

const Charts = styled.div`
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ChartWrapper = styled.div`
  // width: 100%;
`;

export const TooManyItems = () => {
  return (
    <div>
      <h2>Too many items in result set</h2>
      <TotalLabel>
        Our platform can't load this much data, try narrowing the date range and
        try again.
      </TotalLabel>
    </div>
  );
};

const ETAPGraphsContainer = ({
  itemCount,
  eventCount,
  pieData,
  pieDonutData,
  barData,
  loading,
  material,
}: {
  itemCount?: number;
  eventCount?: number;
  pieData?: any;
  pieDonutData?: any;
  barData?: any;
  loading?: boolean;
  material?: String;
}) => {
  if (material !== undefined) material = "(" + material + ")";
  const maxItemCount = Math.pow(10, 6);

  return (
    <div>
      {itemCount < maxItemCount ? (
        <MapStatsGraphs>
          <Charts>
            {material === undefined || material === "(ALL)" ? (
              <Chart>
                <h2>Distribution of All Materials</h2>
                <ChartWrapper>
                  {loading ? (
                    <div>Loading...</div>
                  ) : pieData && pieData.length > 0 ? (
                    <PieChart data={pieData} />
                  ) : (
                    <div>Please select a sublist to show distribution</div>
                  )}
                </ChartWrapper>
              </Chart>
            ) : (
              <Chart>
                <h2>Distribution of {material}</h2>
                <ChartWrapper>
                  {loading ? (
                    <div>Loading...</div>
                  ) : pieDonutData && pieDonutData.length > 0 ? (
                    <PieDonutChart data={pieDonutData} />
                  ) : (
                    <div>Please select a sublist to show top items</div>
                  )}
                </ChartWrapper>
              </Chart>
            )}
            <Chart left={true}>
              <h2>Most Tracked Items {material}</h2>
              <ChartWrapper>
                {loading ? (
                  <div>Loading...</div>
                ) : barData && barData.length > 0 ? (
                  <BarChart data={barData} />
                ) : (
                  <div>Please select a sublist to show top items</div>
                )}
              </ChartWrapper>
            </Chart>
          </Charts>
        </MapStatsGraphs>
      ) : (
        <TooManyItems />
      )}
    </div>
  );
};

export default ETAPGraphsContainer;
