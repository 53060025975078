import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React, { useEffect, useRef, useState } from "react";
import { BarChartType } from "../../../types/charts";
import styled from "styled-components";

const BarChartWrapper = styled.div`
  //   border: 1px solid black;
`;

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const DegradedBar = ({
  data,
  customOptions,
  isMaterialBar,
}: {
  data: any;
  customOptions?: Highcharts.Options;
  isMaterialBar?: boolean;
}) => {
  if (!data || data.length === 0 || typeof window === undefined) {
    return null;
  }
  window.Highcharts = Highcharts;
  console.log({ isMaterialBar });
  console.log({ data });

  const chartConfig: Highcharts.Options = {
    chart: {
      type: "bar",
      animation: true,
      height: 100,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: ["Degraded Ratio"],
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      min: 0,
      title: null,
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
      shared: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Not Degraded",
        type: "bar",
        data: [data.total - data.degraded],
      },
      {
        name: "Degraded",
        type: "bar",
        color: "#edbd7f",
        data: [data.degraded],
      },
    ],
  };
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const chartComponent = useRef({});

  useEffect(() => {
    setWidth(500);
    setHeight(100);
    setTimeout(() => window.dispatchEvent(new Event("resize")));
  }, []);

  return (
    <BarChartWrapper style={{ width, height }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={customOptions ? customOptions : chartConfig}
      />
    </BarChartWrapper>
  );
};

export default DegradedBar;
