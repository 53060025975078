import React, { useRef } from "react";
import { User } from "../../../../types/user";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import {
  TableContainer,
  TableTitle,
  TableTitleRow,
} from "../../../../styles/TableComponents";
import { colors } from "../../../../styles/colors";
import { IconButton, TableFooter, TablePagination } from "@material-ui/core";
import { useState } from "react";
import SurveyResultstoCSV from "../../../../util/data/SurveyResultstoCSV";
import { formatSurveyResultsData } from "../../../../util/data/formatSurveyResultsData";
import { JSONtoCSV } from "../../../../util/data/JSONtoCSV";
import { formatUserData } from "../../../../util/data/formatUserData";
import styled from "styled-components";
import { convertToGeoJSON } from "../../../../util/data/convertToGeoJSON";
import { RowS, RowSTable } from "./SurveyResults";

const UserTable = ({
  itemsData,
  userData,
  listImage,
  isPublic,
}: {
  listImage: string;
  userData: any[];
  itemsData: any[];
  isPublic: boolean;
}) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const headers = [
    { name: "Name", label: "username" },
    { name: "Total Items Tracked", label: "items" },
    { name: "Last Active", label: "date" },
  ];

  const ProjectsTableRow = ({ user }: { user: User }) => {
    // const linkRef = useRef<any>(undefined);
    let itemsCount = 0;
    let last_active = "";
    // const [projectData, setProjectData] = useState<ProjectTableEntity>(project);

    itemsCount = user["items"].length;
    last_active = user["items"][itemsCount - 1].dt;

    // if (user["userId"]) logs = await getUserLogs(user["userId"], accessToken);

    return (
      <TableRow>
        <TableCell>{/* {user["userId"]} */}</TableCell>
        {headers.map((header, i) => (
          <TableCell key={`header_${i}`} align="center">
            {header.label === "items"
              ? user[header.label].length
              : header.label === "date"
              ? new Date(last_active).toLocaleString("en-US")
              : user[header.label]}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const ProjectsTableTitle = () => {
    return (
      <TableTitleRow altFont={true}>
        All ETAP Users
        {userData && <DownloadButton />}
      </TableTitleRow>
    );
  };

  const cellStyle = { color: colors.white };

  const emptyItemsRows =
    rowsPerPage - Math.min(rowsPerPage, userData.length - page * rowsPerPage);

  //download survey
  const DownloadButton = () => {
    const ButtonWrapper = styled.div`
      padding: 10px 24px; /* Some padding */
      cursor: pointer; /* Pointer/hand icon */
      float: left; /* Float the buttons side by side */
      margin-left: 1rem;
      font-size: 12pt;
      color: black;
      border: 1px solid #bfd4e3;
      background: rgb(245, 249, 255, 0.5);
    `;

    let geojson = convertToGeoJSON(userData);

    let test = JSON.stringify(geojson);

    // console.log("test", test)
    const linkRef = useRef<any>(undefined);
    const handleDownload = () => {
      // compute data and convert to csv
      const newDownloadData = SurveyResultstoCSV(formatUserData(userData));

      const csvData = JSONtoCSV(newDownloadData);

      // store data in new blob and create a url
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(csvBlob);

      // set <a> tags href to blob url
      if (linkRef.current) {
        linkRef.current.href = url;
      }
      // click the tag
      linkRef.current && linkRef.current.click();
    };

    return (
      <>
        <ButtonWrapper onClick={handleDownload}>
          Download All User Data
        </ButtonWrapper>
        <a target="_blank" download={"survey-data.csv"} ref={linkRef}></a>
      </>
    );
  };

  return (
    <TableContainer>
      <ProjectsTableTitle />
      <Table>
        <TableHead style={{ backgroundColor: colors.blue }}>
          <TableRow>
            <TableCell />
            {headers.map((header, i) => (
              <TableCell key={i} align="center" style={cellStyle}>
                {header.name}
              </TableCell>
            ))}
            {/* <TableCell style={cellStyle}>Items</TableCell>
            <TableCell style={cellStyle}>Logs</TableCell>
            {isPublic === false && (<TableCell style={cellStyle}>Actions</TableCell>)} */}
          </TableRow>
        </TableHead>
        <TableBody>
          {userData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((user: User, i) => (
              <ProjectsTableRow key={`project_row_${i}`} user={user} />
            ))}
          {emptyItemsRows > 0 && (
            <TableRow style={{ height: 33 * emptyItemsRows }}>
              <TableCell colSpan={4} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              page={page}
              colSpan={4}
              count={userData.length}
              rowsPerPage={rowsPerPage}
              onChangePage={(
                _e: any,
                newPage: React.SetStateAction<number>
              ) => {
                setPage(newPage);
              }}
              onChangeRowsPerPage={(e: { target: { value: string } }) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
