import * as React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import ETAPContextProvider from "../components/Dashboards/EtapDashboard/Context/ETAPContextProvider";
import ETAPDashboard from "../components/Dashboards/EtapDashboard/ETAPDashboard";

const ETAPPage = () => {
  return (
    <ETAPContextProvider>
      <Layout vertical={false}>
        <Helmet>
          <script src="https://kit.fontawesome.com/4673245c66.js"></script>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.4.2/leaflet.draw.css"
          />
          <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css" />
          <script src="https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.4.2/leaflet.draw.js"></script>
        </Helmet>
        <Seo title="EPA's ETAP Data Portal" />
        <ETAPDashboard />
      </Layout>
    </ETAPContextProvider>
  );
};

export default ETAPPage;
