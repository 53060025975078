import React, { useContext, useRef, useState } from "react";
import Button from "../../../Button/Button";
import {
  ETAPFiltersContainer,
  FiltersTitle,
  FiltersWrapper,
} from "../../../DataPlatform/Filters/FiltersComponents";
import styled from "styled-components";
import { colors } from "../../../../styles/colors";
import dayjs from "dayjs";
import GeoJSONtoCSV from "../../../../util/data/GeoJSONtoCSV";
import { formatEtapDownloadData } from "../../../../util/data/formatEtapDownloadData";
import { JSONtoCSV } from "../../../../util/data/JSONtoCSV";
import { DebrisTrackerContext } from "../../../../context/DebrisTrackerContext";
import SublistSelect from "../SublistSelect";
import ETAPRangeFilter from "./ETAPRangeFilter";
import { ETAPContext } from "../Context/ETAPContext";
import { FilterType } from "../../../../types/filters";

const FilterErrorMessage = styled.p`
  color: ${colors.orange};
  text-align: center;
  margin-bottom: 0px;
`;

const ETAPFilters = ({ geojson }: { geojson: GeoJSON.FeatureCollection }) => {
  const { filters, loadData, setData, loading } = useContext(ETAPContext);

  useContext(DebrisTrackerContext);

  const linkRef = useRef<any>(undefined);

  const [err, setErr] = useState<string>("");

  const setErrMessage = (str: string) => {
    setErr(str);
    setTimeout(() => {
      setErr("");
    }, 2000);
  };

  const filtersRef = useRef<FilterType>(filters);

  const shouldFetch = () => {
    if (
      filters.date_type === "range" &&
      dayjs(filters.start_date).isAfter(filters.end_date)
    ) {
      setErrMessage("Invalid Date Range");
      return false;
    }

    if (filters.project) {
      if (
        filters.project !== filtersRef.current.project ||
        filters.date_type !== filtersRef.current.date_type ||
        (filters.date_type === "range" &&
          filters.start_date !== filtersRef.current.start_date) ||
        (filters.date_type === "range" &&
          filters.end_date !== filtersRef.current.end_date) ||
        (filters.date_type === "exact" &&
          filters.exact_date !== filtersRef.current.exact_date)
      ) {
        return true;
      }
    }
    return false;
  };

  const searchData = async () => {
    if (shouldFetch()) {
      const res = await loadData(filters);
      setData(res);
      filtersRef.current = filters;
    } else {
      filtersRef.current = filters;
    }
  };

  const DownloadButton = () => {
    const ButtonWrapper = styled.div`
      margin-top: 10px;
      text-align: center;
      padding: 15px 0px;
      cursor: pointer;
    `;

    const handleDownload = () => {
      // compute data and convert to csv
      const newDownloadData = GeoJSONtoCSV(
        formatEtapDownloadData(geojson, filters.project === "all")
      );
      const csvData = JSONtoCSV(newDownloadData);

      // store data in new blob and create a url
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(csvBlob);

      // set <a> tags href to blob url
      if (linkRef.current) {
        linkRef.current.href = url;
      }
      // click the tag
      linkRef.current && linkRef.current.click();
    };

    return (
      <>
        <ButtonWrapper onClick={handleDownload}>
          Download Selected Sublist(s) Data
        </ButtonWrapper>
        <a target="_blank" download={"mdt-data.csv"} ref={linkRef}></a>
      </>
    );
  };

  return (
    <ETAPFiltersContainer>
      <FiltersTitle>Filter</FiltersTitle>
      {err && <FilterErrorMessage>{err}</FilterErrorMessage>}
      <FiltersWrapper>
        <SublistSelect />
        <ETAPRangeFilter />
        <Button
          style={{
            width: "100%",
            marginTop: "10px",
            padding: "10px 0px",
            opacity: filters.project ? "1" : "0.75",
            cursor: filters.project ? "pointer" : "default",
          }}
          onClick={filters.project && !loading ? searchData : null}
        >
          Search
        </Button>
        {geojson && geojson.features.length > 0 && <DownloadButton />}
      </FiltersWrapper>
    </ETAPFiltersContainer>
  );
};

export default ETAPFilters;
