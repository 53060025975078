import { FeatureCollection, Geometry } from "geojson";
import { cloneDeep } from "lodash";

const etap_metadata = [
  "list_name",
  "sublist_name",
  "material",
  "etap_1_name",
  "etap_2_name",
  "quantity",
  "degraded",
  "description",
  "latitude",
  "longitude",
  "altitude",
  "radius",
  "location",
  "timestamp",
  "dt",
  "project_name",
  "username",
  "manual_upload",
  "eventname",
  "id",
  "log_index",
];

export const formatEtapDownloadData = (
  geojson: FeatureCollection<Geometry, { [name: string]: any }>,
  allOrg: boolean
) => {
  const comparatorList = etap_metadata;
  const geojsonCopy = cloneDeep(geojson);
  // ensure data properties match metadata doc
  geojsonCopy.features.forEach((feature) => {
    Object.keys(feature.properties).forEach((keyname) => {
      if (comparatorList.includes(keyname)) return;
      delete feature.properties[keyname];
    });
  });
  return geojsonCopy;
};
