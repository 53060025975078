import React, { useContext, useEffect } from "react";
import { DataPlatformContext } from "../../../context/DataPlatform/DataPlatformContext";
import { getOrganization } from "../../../dataApi/getOrganizations";
import { getEtapProject } from "../../../dataApi/getProjects";
import { useProjectsByList } from "../../../util/hooks/useProjects";
import Select from "../../Forms/Select";
import { ETAPContext } from "./Context/ETAPContext";

const SublistSelect = () => {
  const { filters, setFilters } = useContext(ETAPContext);
  const { projects, loading: projectsLoading } = useProjectsByList("153");

  // create list name for select to account for long names
  const createListName = (name: string) => {
    if (name.length > 30) {
      return name.substr(0, 30) + "...";
    } else {
      return name;
    }
  };

  const selectData: { label: string; value: string }[] = [
    {
      label: "All Sublists",
      value: "-1",
    },
  ];

  if (projects) {
    projects.forEach((project1) => {
      if (project1.verified != 0) {
        selectData.push({ label: project1.name, value: `${project1.id}` });
      }
    });
  }

  return (
    <Select
      onChange={async (val) => {
        if (val === "-1") {
          setFilters({ ...filters, project: "all" });
          return;
        }
        // // fetch detailed list data
        const project = await getEtapProject(val);
        setFilters({ ...filters, project: project });
      }}
      data={selectData}
      title={
        !filters.project && projectsLoading
          ? "Loading..."
          : filters.project
          ? filters.project === "all"
            ? "All Sublists"
            : createListName(filters.project.name)
          : "Select a Sublist"
      }
    ></Select>
  );
};

export default SublistSelect;
