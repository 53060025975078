import RadioGroup from "@material-ui/core/RadioGroup";
import styled from "styled-components";
import React, { useContext } from "react";
import RadioButton from "../../../Forms/RadioButton";
import { ETAPContext } from "../Context/ETAPContext";

export const FilterRadioGroup = styled(RadioGroup)`
  color: rgba(255, 255, 255, 1);
`;

const FilterDate = styled.input`
  margin: 10px 0px;
  font-family: "Lato";
  @media only screen and (max-width: 478px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const RadioGroupFilterLabel = styled.span`
  margin-right: 10px;
`;

const ETAPRangeFilter = () => {
  const { filters, setFilters } = useContext(ETAPContext);

  const setDateType = (type: "all" | "exact" | "range") => {
    setFilters({ ...filters, date_type: type });
  };

  const handleDateChange = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  return (
    <FilterRadioGroup>
      <RadioButton
        checked={filters.date_type === "all"}
        name="all"
        onChange={() => {
          setDateType("all");
        }}
      >
        All
      </RadioButton>
      {/* Exact Date */}
      <RadioButton
        checked={filters.date_type === "exact"}
        name="exact"
        onChange={() => {
          setDateType("exact");
        }}
      >
        <RadioGroupFilterLabel>Exact</RadioGroupFilterLabel>
        <FilterDate
          name="exact_date"
          type="date"
          defaultValue={filters.exact_date}
          onChange={handleDateChange}
        />
      </RadioButton>
      {/* Date Range */}
      <RadioButton
        checked={filters.date_type === "range"}
        name="range"
        onChange={() => {
          setDateType("range");
        }}
      >
        <RadioGroupFilterLabel>Range</RadioGroupFilterLabel>
      </RadioButton>
      <FilterDate
        name="start_date"
        type="date"
        defaultValue={filters.start_date}
        onChange={handleDateChange}
      />
      <FilterDate
        name="end_date"
        type="date"
        defaultValue={filters.end_date}
        onChange={handleDateChange}
      />
    </FilterRadioGroup>
  );
};

export default ETAPRangeFilter;
