import React, { useEffect, useState } from "react";
import {
  BarChartType,
  PieChartDataType,
  PieDonutChartDataType,
} from "../../../types/charts";
import {
  geojsonToBar,
  geojsonToBarETAP,
  geojsonToMaterialBarETAP,
  geojsonToMaterialPie,
  geojsonToPie,
  geojsonToPieDonut,
} from "../../../util/data/chartFunctions";
import ETAPGraphsContainer from "./ETAPGraphsContainer";

const ETAPGraphs = ({
  geojson,
  loading,
  material,
}: {
  geojson: GeoJSON.FeatureCollection;
  loading: boolean;
  material?: String;
}) => {
  const [itemCount, setItemCount] = useState<number>(0);
  const [eventCount, setEventCount] = useState<number>(0);
  const [pieData, setPieData] = useState<PieChartDataType>([]);
  const [pieDonutData, setPieDonutData] = useState<PieDonutChartDataType[]>();
  const [barData, setBarData] = useState<BarChartType>([]);

  // calculate new counts
  useEffect(() => {
    if (!geojson) {
      return;
    }
    let newItemCount = 0;
    let newEventCount = 0;
    geojson.features.forEach((item) => {
      newItemCount += parseInt(item.properties.quantity, 10);
      newEventCount += 1;
    });
    setItemCount(newItemCount);
    setEventCount(newEventCount);
  }, [geojson]);

  // new pie data
  useEffect(() => {
    const newPieData = geojsonToPie(geojson);
    setPieData(newPieData);
  }, [geojson, material]);

  // new pie donut data
  useEffect(() => {
    if (material || material !== "ALL") {
      const newPieDonutData = geojsonToPieDonut(geojson, material);
      setPieDonutData(newPieDonutData);
    }
  }, [geojson, material]);

  // new bar data
  useEffect(() => {
    if (!material || material === "ALL") {
      const data = geojsonToBarETAP(geojson);
      setBarData(data);
    } else {
      const newBarData = geojsonToMaterialBarETAP(geojson, material);
      setBarData(newBarData);
    }
  }, [geojson, material]);

  return (
    <>
      <ETAPGraphsContainer
        loading={loading}
        itemCount={itemCount}
        eventCount={eventCount}
        pieData={pieData}
        pieDonutData={pieDonutData}
        barData={barData}
        material={material}
      />
    </>
  );
};

export default ETAPGraphs;
