import React, { useEffect, useState } from "react";
import {
  BarChartType,
  PieChartDataType,
  PieDonutChartDataType,
} from "../../../types/charts";
import {
  geojsonToBar,
  geojsonToBarETAP,
  geojsonToDegradedBar,
  geojsonToDegradedMaterialBar,
  geojsonToMaterialBarETAP,
  geojsonToMaterialPie,
  geojsonToPie,
  geojsonToPieDonut,
} from "../../../util/data/chartFunctions";
import DegradedBar from "./DegradedBar";
import ETAPGraphsContainer, { TooManyItems } from "./ETAPGraphsContainer";

const DegradedBarContainer = ({
  geojson,
  loading,
  material,
}: {
  geojson: GeoJSON.FeatureCollection;
  loading: boolean;
  material?: String;
}) => {
  const [itemCount, setItemCount] = useState<number>(0);
  const [degradedGraphData, setDegradedGraphData] = useState<any>();
  let titleMaterial = "";
  if (material !== undefined) {
    titleMaterial = "(" + material + ")";
  } else {
    titleMaterial = "(ALL)";
  }

  // calculate new counts
  useEffect(() => {
    if (!geojson) {
      return;
    }
    let newItemCount = 0;
    geojson.features.forEach((item) => {
      newItemCount += parseInt(item.properties.quantity, 10);
    });
    setItemCount(newItemCount);
  }, [geojson]);

  // degraded data
  useEffect(() => {
    if (!material || material === "(ALL)") {
      const degradedData = geojsonToDegradedBar(geojson);
      setDegradedGraphData(degradedData);
    } else {
      const degradedData = geojsonToDegradedMaterialBar(geojson, material);
      setDegradedGraphData(degradedData);
    }
  }, [geojson, material]);
  const maxItemCount = Math.pow(10, 6);

  return (
    <>
      {itemCount < maxItemCount ? (
        <>
          {material === undefined || material === "ALL" ? (
            <div>
              <h2>Degraded Distribution</h2>
              {loading ? (
                <div>Loading...</div>
              ) : degradedGraphData ? (
                <DegradedBar data={degradedGraphData} />
              ) : (
                <div>Please select a sublist to show distribution</div>
              )}
            </div>
          ) : (
            <div>
              <h2>Degraded Distribution {titleMaterial}</h2>
              {loading ? (
                <div>Loading...</div>
              ) : degradedGraphData ? (
                <DegradedBar data={degradedGraphData} isMaterialBar={true} />
              ) : (
                <div>Please select a sublist to show distribution</div>
              )}
            </div>
          )}
        </>
      ) : (
        <TooManyItems />
      )}
    </>
  );
};

export default DegradedBarContainer;
