import React from "react";
import { ProjectsEntity } from "../../../../types/list";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import {
  TableContainer,
  TableTitle,
  TableTitleRow,
} from "../../../../styles/TableComponents";
import { TableFooter, TablePagination } from "@material-ui/core";
// import { TablePagination } from '@mui/material';
import { colors } from "../../../../styles/colors";
import numberWithCommas from "../../../../util/numberWithCommas";
import { useRef } from "react";
import { useState } from "react";
import { useListDashboardData } from "../../../../util/hooks/useListDashboardData";
import styled from "styled-components";
import { formatSurveyResultsData } from "../../../../util/data/formatSurveyResultsData";
import { JSONtoCSV } from "../../../../util/data/JSONtoCSV";
import { convertToGeoJSON } from "../../../../util/data/convertToGeoJSON";
import SurveyResultstoCSV from "../../../../util/data/SurveyResultstoCSV";
interface ProjectTableEntity extends ProjectsEntity {
  itemCount: number;
  logCount: number;
}

const SublistsTable = ({
  projectsData,
  itemsData,
  listImage,
  isPublic,
  mdtEtaps,
}: {
  listImage: string;
  projectsData: ProjectsEntity[];
  itemsData: any[];
  isPublic: boolean;
  mdtEtaps: any;
}) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const groupedProjects = _.groupBy(itemsData, "project_id");
  const itemsByProjectId = Object.entries(groupedProjects);

  const logsByProject = itemsByProjectId.map((value) => {
    const groupedByLog = _.groupBy(value[1], "log_index");
    return [value[0], Object.keys(groupedByLog).length];
  });

  const headers = [
    // { name: "Id", label: "id" },
    { name: "Project Name", label: "name" },
    { name: "Verified", label: "verified" },
  ];

  const SublistsTableRow = ({ project }: { project: ProjectTableEntity }) => {
    const [projectData, setProjectData] = useState<ProjectTableEntity>(project);

    return (
      <TableRow>
        <TableCell>
          {projectData.icon_uri ? (
            <img width={50} height={50} src={projectData.icon_uri} />
          ) : (
            <img
              width={50}
              height={50}
              src={
                listImage &&
                `https://marinedebris.engr.uga.edu/mdtapp/images/${listImage}`
              }
            />
          )}
        </TableCell>
        {/* logsByProject */}
        {headers.map((header, i) => (
          <TableCell key={`header_${i}`} align="center">
            {projectData[header.label]}
          </TableCell>
        ))}

        <TableCell>{numberWithCommas(projectData.itemCount)}</TableCell>
        <TableCell>{numberWithCommas(projectData.logCount)}</TableCell>
      </TableRow>
    );
  };
  const { surveyResults } = useListDashboardData("153");
  //download survey
  const DownloadButton = () => {
    const ButtonWrapper = styled.div`
      padding: 10px 24px; /* Some padding */
      cursor: pointer; /* Pointer/hand icon */
      float: left; /* Float the buttons side by side */
      margin-left: 1rem;
      font-size: 12pt;
      color: black;
      border: 1px solid #bfd4e3;
      background: rgb(245, 249, 255, 0.5);
    `;

    // console.log("test", test)
    const linkRef = useRef<any>(undefined);
    const handleDownload = () => {
      // compute data and convert to csv
      const newDownloadData = SurveyResultstoCSV(
        formatSurveyResultsData(surveyResults)
      );

      const csvData = JSONtoCSV(newDownloadData);

      // store data in new blob and create a url
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(csvBlob);

      // set <a> tags href to blob url
      if (linkRef.current) {
        linkRef.current.href = url;
      }
      // click the tag
      linkRef.current && linkRef.current.click();
    };

    return (
      <>
        <ButtonWrapper onClick={handleDownload}>
          Download All Sublist Data
        </ButtonWrapper>
        <a target="_blank" download={"survey-data.csv"} ref={linkRef}></a>
      </>
    );
  };

  const ProjectsTableTitle = () => {
    return (
      <TableTitleRow altFont={true}>
        All ETAP Verified Sublists
        {projectsData && <DownloadButton />}
      </TableTitleRow>
    );
  };

  const cellStyle = { color: colors.white };

  //add computed aggregates to project object
  // populates "Items" and "Logs" columns
  projectsData.forEach((project) => {
    if (mdtEtaps) {
      const itemsObject = {};
      mdtEtaps.forEach((x) => {
        itemsObject[x["project"]] = x["list_data"].length;
      });
      project["itemCount"] = itemsObject[project.id] || 0;
    }

    if (logsByProject.length > 0) {
      const logsObject = {};
      logsByProject.forEach((x) => {
        logsObject[x[0]] = x[1];
      });
      project["logCount"] = logsObject[project.id] || 0;
    }
  });

  const emptyItemsRows =
    rowsPerPage -
    Math.min(rowsPerPage, projectsData.length - page * rowsPerPage);

  return (
    <TableContainer>
      <ProjectsTableTitle />
      <Table>
        <TableHead style={{ backgroundColor: colors.blue }}>
          <TableRow>
            <TableCell />
            {headers.map((header, i) => (
              <TableCell key={`${i}`} align="center" style={cellStyle}>
                {header.name}
              </TableCell>
            ))}
            <TableCell style={cellStyle}>Items</TableCell>
            <TableCell style={cellStyle}>Logs</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {itemsByProjectId &&
            logsByProject &&
            projectsData
              .filter((project) => project.verified !== 0)
              .sort(
                (a: ProjectTableEntity, b: ProjectTableEntity) =>
                  b.logCount - a.logCount
              )
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((project: ProjectTableEntity, i) => (
                <SublistsTableRow key={`project_row_${i}`} project={project} />
              ))}
          {emptyItemsRows > 0 && (
            <TableRow style={{ height: 33 * emptyItemsRows }}>
              <TableCell colSpan={0} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              page={page}
              colSpan={4}
              count={projectsData.length}
              rowsPerPage={rowsPerPage}
              onChangePage={(
                _e: any,
                newPage: React.SetStateAction<number>
              ) => {
                setPage(newPage);
              }}
              onChangeRowsPerPage={(e: { target: { value: string } }) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default SublistsTable;
