import React, { useEffect, useState } from "react";
import { getMdtEtaps } from "../../../../dataApi/getProjects";
import { BarChartType, PieChartDataType } from "../../../../types/charts";
import { ItemInstance, ListType, ProjectsEntity } from "../../../../types/list";
import {
  geojsonToBar,
  geojsonToMaterialPie,
  geojsonToPie,
} from "../../../../util/data/chartFunctions";
import { useListDashboardData } from "../../../../util/hooks/useListDashboardData";
import ProjectsTable from "../../ListDashboard/ProjectsTable";
import ETAPGraphsContainer from "../ETAPGraphsContainer";
import SublistsTable from "./SublistTable";
import SurveyResults from "./SurveyResults";
import UserTable from "./UserTable";

const ETAPTables = ({
  geojson,
  loading,
  projects,
  itemsData,
  userData,
  org,
  table,
}: {
  geojson: GeoJSON.FeatureCollection;
  loading: boolean;
  projects?: ProjectsEntity[];
  itemsData?: ItemInstance[];
  userData?: any[];
  org?: ListType;
  table?: String;
}) => {
  const [itemCount, setItemCount] = useState<number>(0);
  const [eventCount, setEventCount] = useState<number>(0);
  const [pieData, setPieData] = useState<PieChartDataType>([]);
  const [barData, setBarData] = useState<BarChartType>([]);
  const [mdtEtaps, setMdtEtaps] = useState<[]>([]);

  // calculate new counts
  useEffect(() => {
    (async () => {
      const asyncMdtEtaps = getMdtEtaps().then((data) => {
        return data;
      });
      setMdtEtaps(await asyncMdtEtaps);
    })();
  }, []);

  // calculate new counts
  useEffect(() => {
    if (!geojson) {
      return;
    }
    let newItemCount = 0;
    let newEventCount = 0;
    geojson.features.forEach((item) => {
      newItemCount += parseInt(item.properties.quantity, 10);
      newEventCount += 1;
    });
    setItemCount(newItemCount);
    setEventCount(newEventCount);
  }, [geojson]);

  if (table === "Survey") {
    return (
      <>
        {projects && (
          <SurveyResults
            listImage={org && org.icon_image_filename}
            projectsData={projects}
            itemsData={itemsData}
            isPublic={true}
          />
        )}
      </>
    );
  } else if (table === "Sublist") {
    return (
      <>
        {projects && (
          <SublistsTable
            listImage={org && org.icon_image_filename}
            projectsData={projects}
            itemsData={itemsData}
            isPublic={true}
            mdtEtaps={mdtEtaps}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        {projects && (
          <UserTable
            listImage={org && org.icon_image_filename}
            userData={userData}
            itemsData={itemsData}
            isPublic={true}
          />
        )}
      </>
    );
  }
};

export default ETAPTables;
