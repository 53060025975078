import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";
import { PieDonutChartDataType } from "../../../types/charts";
import { PieChartWrapper } from "./ChartComponents";

const PieDonutChart = ({ data }: { data: PieDonutChartDataType[] }) => {
  var colors = Highcharts.getOptions().colors;

  let layer1Data = [];
  let layer2Data = [];
  for (let i = 0; i < data.length; i++) {
    layer1Data.push({
      name: data[i].drilldown.name,
      y: data[i].y,
      color: colors[i],
    });
    // console.log("colors", colors[i]);
    for (let j = 0; j < data[i].drilldown.data.length; j++) {
      const brightness = 0.2 - j / data[i].drilldown.data.length / 5;
      layer2Data.push({
        name: data[i].drilldown.categories[j],
        y: data[i].drilldown.data[j],
        color: Highcharts.color(colors[i]).brighten(brightness).get(),
      });
      // console.log("colors", Highcharts.color(data[i].color));
    }
  }

  const chartConfig: Highcharts.Options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      animation: false,
      type: "pie",
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        center: ["50%", "50%"],
      },
    },
    series: [
      {
        name: "ETAP 1.0",
        data: layer1Data,
        size: "60%",
        dataLabels: {
          formatter: function () {
            return this.y > 0 ? this.point.name : null;
          },
          color: "#ffffff",
          distance: 10,
        },
      },
      {
        name: "ETAP 2.0",
        data: layer2Data,
        size: "80%",
        innerSize: "60%",
        dataLabels: {
          enabled: false,
          // formatter: function () {
          //   // display only if larger than 1
          //   return this.y > 1
          //     ? "<b>" + this.point.name + ":</b> " + this.y + "%"
          //     : null;
          // },
        },
        id: "versions",
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
          },
          chartOptions: {
            series: [
              {},
              {
                id: "versions",
                dataLabels: {
                  enabled: false,
                },
              },
            ],
          },
        },
      ],
    },
  };
  return (
    <PieChartWrapper>
      <HighchartsReact highcharts={Highcharts} options={chartConfig} />
    </PieChartWrapper>
  );
};

export default PieDonutChart;
