import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useListDashboardData } from "../../../util/hooks/useListDashboardData";
import { DataPlatformContext } from "../../../context/DataPlatform/DataPlatformContext";
import { convertToGeoJSON } from "../../../util/data/convertToGeoJSON";

import _ from "lodash";
import ETAPGraphs from "./ETAPGraphs";
import ETAPFilter from "./Filters/ETAPFilter";
import { applyFilters } from "../../../util/data/itemsDataFunctions";
import pointsWithinPolygon from "@turf/points-within-polygon";
import Select from "../../Forms/Select";
import {
  geojsonToBar,
  geojsonToDegradedBar,
  geojsonToSelect,
} from "../../../util/data/chartFunctions";
import ETAPTables from "./Tables/ETAPTables";
import { ETAPContext } from "./Context/ETAPContext";
import ItemsMap from "../../DataPlatform/ItemsMap";
import { any, string } from "prop-types";
import { formatEtapDownloadData } from "../../../util/data/formatEtapDownloadData";
import { BarSeries } from "../../../types/charts";
import DegradedBar from "./DegradedBar";
import DegradedBarContainer from "./DegradedBarContainer";

const countItems = (items: any[], key: string) => {
  return Object.entries(_.groupBy(items, key))
    .map((k) => {
      return { name: k[0], count: k[1].length, item: k[1][0] };
    })
    .sort((a, b) => b.count - a.count);
};

const DataPlatformContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const ETAP = {
  Row: styled.div`
    flex-direction: row;
    display: table;
  `,
  RowS: styled.div`
    display: flex;
    align-items: center;

    // border: 1px solid black;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  `,
  Col1: styled.div`
    flex-direction: col;
    width: 20%;
    height: 520px;
    float: left;
  `,
  Col: styled.div`
    flex-direction: col;
    // width: 80%;
    height: auto;
    float: left;
  `,
  ColS: styled.div`
    flex-direction: col;
    // width: 80%;
    height: auto;
    float: left;
  `,
  StatCard: styled.div`
    margin: 20px 20px;
    padding: 0px 20px;
    width: auto;
    height: auto;
    border: 1px solid #bfd4e3;
    background-color: white;

    &:hover: {
      background-color: "#e2e8f0";
      cursor: pointer;
    }
  `,
  Graphs: styled.div`
    display: flex;
    flex-direction: row;
  `,
  Charts: styled.div`
    display: flex;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  `,
  Chart: styled.div`
    margin-left: ${({ left }) => (left ? "20px" : "0px")};

    @media only screen and (max-width: 768px) {
      margin-left: 0px;
    }
  `,
  ChartWrapper: styled.div`
    min-width: 30vw;
  `,
  Button: styled.div`
    padding: 10px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
    margin-right: 15px;
    border: 1px solid #bfd4e3;
    background: rgb(245, 249, 255);
  `,
  ButtonSelect: styled.div`
    padding: 10px 24px; /* Some padding */
    cursor: pointer; /* Pointer/hand icon */
    float: left; /* Float the buttons side by side */
    margin-right: 4rem;
    padding: 2rem;
    border: 1px solid #bfd4e3;
    background: rgb(245, 249, 255);
    width: auto;
    height: 100%;
  `,
};

const ETAPWrapper = styled.div`
  font-family: "Lato";
  color: #536275;
  background: rgb(245, 249, 255);
  box-shadow: inset 0px 0px 0px 10px #536275;
  display: flex;
  height: 100%;
  width: !100vw;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 991px) {
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 478px) {
  }
`;

const ETAPTitle = styled.h1`
  font-size: 22px;
  margin-bottom: 0px;
  margin-top: -63px;
  background-color: white;
  z-index: 1200;
  padding: 19px;
  padding-left: 50px;
  margin-left: -20px;
  width: 600px;

  @media only screen and (max-width: 991px) {
    margin: 0px;
    padding-left: 0px;
  }
  @media only screen and (max-width: 478px) {
    width: auto;
  }
`;

const LocationsMapContainer = styled.div`
  height: 50vh;
  width: 100vw;
`;

const ETAPDashboard = () => {
  const { filters, data, loading } = useContext(ETAPContext);
  const [selectMaterialList, setSelectMaterialList] = useState([
    { label: "", value: "" },
  ]);
  const [selectedTable, setSelectedTable] = useState<String>();
  const [selectedGraph, setSelectedGraph] = useState<String>();
  const [degradedGraphData, setDegradedGraphData] = useState<any>();
  const listId = "153";

  const {
    org,
    projects,
    itemsData,
    userData,
    totalItems,
    admins,
    logCount,
    surveyResults,
  } = useListDashboardData(listId);

  // drawn polygon
  const [filterPolygon, setFilterPolygon] = useState<L.Polygon>(undefined);

  //copy of the raw geojson data. used when data needs to be filtered without fetching new data.
  const defaultGeojson: GeoJSON.FeatureCollection = {
    type: "FeatureCollection",
    features: [],
  };

  const unfilteredGeojson = useRef<GeoJSON.FeatureCollection>(defaultGeojson);

  const [geojson, setGeojson] = useState<GeoJSON.FeatureCollection>(
    unfilteredGeojson.current
  );

  // update data
  useEffect(() => {
    if (data && data.length > 0) {
      let geojson = convertToGeoJSON(data);
      unfilteredGeojson.current = geojson;
      let filteredGeojson = geojson;

      const newPieData = geojsonToSelect(filteredGeojson);
      newPieData.forEach((material) => {
        materialList.push({ label: material.label, value: material.value });
      });

      setSelectMaterialList(materialList);
      setGeojson(filteredGeojson);
    } else if (data && data.length === 0) {
      const newPieData = geojsonToSelect(defaultGeojson);

      newPieData.forEach((material) => {
        materialList.push({ label: material.label, value: material.value });
      });

      setSelectMaterialList(materialList);
      setGeojson(defaultGeojson);
    }
  }, [data]);

  // update on filter change
  useEffect(() => {
    if (data && data.length > 0) {
      setGeojson(applyFilters(filters, unfilteredGeojson.current));
    }
  }, [filters]);

  // update data on polygon change
  useEffect(() => {
    if (data && data.length > 0) {
      if (filterPolygon === undefined) {
        setGeojson(applyFilters(filters, unfilteredGeojson.current));
      } else {
        const points = pointsWithinPolygon(
          // @ts-ignore
          geojson,
          filterPolygon.toGeoJSON()
        );
        setGeojson(points);
      }
    }
  }, [filterPolygon]);

  const materialList: { label: string; value: string }[] = [
    {
      label: "ALL MATERIALS",
      value: "ALL",
    },
  ];

  const selectDropdownGraphHandler = (material) => {
    setSelectedGraph(material);
  };

  const selectTableHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTable(event.target.id);
  };

  return (
    <DataPlatformContainer>
      <LocationsMapContainer>
        {typeof window !== "undefined" && (
          <ItemsMap
            cluster={true}
            loading={loading}
            geojson={geojson}
            filters={filters}
            interactive={true}
            setFilterPolygon={setFilterPolygon}
            draw={true}
          />
        )}
      </LocationsMapContainer>
      <ETAPTitle>
        EPA’s Escaped Trash Assessment Protocol (ETAP) Portal
      </ETAPTitle>
      <ETAPWrapper>
        <ETAPFilter geojson={geojson} />

        {/* Map Stats */}
        <div>
          <ETAP.Row>
            <ETAP.StatCard style={{ padding: "20px 20px" }}>
              <ETAP.RowS>
                <ETAP.ButtonSelect>
                  <Select
                    onChange={selectDropdownGraphHandler}
                    data={selectMaterialList}
                    title={"Filter Top Items By Material"}
                  ></Select>
                </ETAP.ButtonSelect>
                <div>
                  <DegradedBarContainer
                    geojson={geojson}
                    material={selectedGraph}
                    loading={loading}
                  />
                </div>
              </ETAP.RowS>
              <ETAPGraphs
                loading={loading}
                geojson={geojson}
                material={selectedGraph}
              />
            </ETAP.StatCard>
          </ETAP.Row>
        </div>
      </ETAPWrapper>

      {/* Table Section */}
      <ETAP.Row>
        <ETAP.StatCard>
          <ETAP.Row style={{ margin: "20px 0px" }}>
            <ETAP.Col>
              <ETAP.Button onClick={selectTableHandler} id="User">
                Users
              </ETAP.Button>
              <ETAP.Button onClick={selectTableHandler} id="Sublist">
                Sublists
              </ETAP.Button>
              <ETAP.Button onClick={selectTableHandler} id="Survey">
                Survey Results
              </ETAP.Button>
            </ETAP.Col>
          </ETAP.Row>
          <ETAPTables
            geojson={geojson}
            projects={projects}
            loading={loading}
            itemsData={itemsData}
            userData={userData}
            table={selectedTable}
            org={org}
          />
        </ETAP.StatCard>
      </ETAP.Row>
    </DataPlatformContainer>
  );
};

export default ETAPDashboard;
