import React, { useEffect, useState } from "react";
import { FilterType, CheckboxType } from "../../../../types/filters";
import { ETAPContext } from "./ETAPContext";
import dayjs from "dayjs";
import { ListType, ProjectsEntity } from "../../../../types/list";
import { useETAPItemData, useItemData } from "../../../../util/useItemData";
import { useAllOrgData, useOrgData } from "../../../../util/hooks/useOrgData";
import useGlobalMaterials from "../../../../util/hooks/useGlobalMaterials";
import { useProjectsByList } from "../../../../util/hooks/useProjects";

// interface DataPlatformState {
//   orgs: ListType[];
//   filters: FilterType;
// }

// interface DataPlatformActions {
//   LOAD_ORGS: "LOAD_ORGS";
//   UPDATE_SELECTED_ORG: "UPDATE_SELECTED_ORG";
//   LOAD_POINTS: "LOAD_POINTS";
//   LOAD_STATS: "LOAD_STATS";
//   LOAD_DETAILS: "LOAD_DETAILS";
// }

const ETAPContextProvider = ({ children }: { children?: any }) => {
  const { org: organization, loading: orgLoading } = useOrgData("153");
  const defaultFilters: FilterType = {
    checkboxes: [],
    date_type: "range",
    exact_date: dayjs().format("YYYY-MM-DD"),
    start_date: dayjs().subtract(1, "M").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
    list: undefined,
    project: undefined,
    user_data: -1,
    showManual: false,
  };

  //TODO: Reimplement filter state management as reducer
  // const filterReducer = (prev: FilterType, action: any) => {};

  const [filters, setFilters] = useState<FilterType>(defaultFilters);

  const [data, setData] = useState<any[]>([]);

  const { projects, loading: projectsLoading } = useProjectsByList("153");
  const { loadData, loading: itemsLoading } = useETAPItemData(); // ETAP TO DO: use useETAPItemData
  const { loading: globalMaterialsLoading } = useGlobalMaterials();

  /*********************/
  /** Update handlers **/
  /*********************/
  useEffect(() => {
    setFilters({ ...filters, list: organization });
  }, [organization]);

  const dataPlatformState = {
    projects: projects,
    loading: projectsLoading || itemsLoading || globalMaterialsLoading,

    data,
    setData,

    filters,
    setFilters,

    loadData,
  };

  return (
    <ETAPContext.Provider value={dataPlatformState}>
      {children}
    </ETAPContext.Provider>
  );
};

export default ETAPContextProvider;
