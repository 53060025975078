export const formatUserData = (userData: any[]) => {
  const output: any[] = [];

  // ensure data properties match metadata doc
  console.log({ userData });
  userData.forEach((user) => {
    //   const answers: {} = {};
    //   for (let i = 0; i < result["results_json"].length; i++) {
    //     answers[i + 1 + ". " + result["survey_json"]["questions"][i]["text"]] =
    //       result["results_json"][i];
    //   }
    //   output.push({
    //     user_id: result.user_id,
    //     log_id: result.log_id,
    //     ...answers,
    //   });
  });
  return output;
};
