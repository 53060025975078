import React, { useContext, useEffect, useRef } from "react";
import { ProjectsEntity } from "../../../../types/list";
import { colors } from "../../../../styles/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import { TableContainer, TableTitle } from "../../../../styles/TableComponents";
import { useState } from "react";
import { verifyProjectById } from "../../../../dataApi/verifyProject";
import { useListDashboardData } from "../../../../util/hooks/useListDashboardData";
import { BarChartType, PieChartDataType } from "../../../../types/charts";
// import { Chart } from "highcharts";
import styled from "styled-components";
import BarChart from "../../../DataPlatform/Charts/BarChart";
import PieChart from "../../../DataPlatform/Charts/PieChart";
import SurveyTable from "./SurveyTable";
import GeoJSONtoCSV from "../../../../util/data/GeoJSONtoCSV";
import { formatSurveyResultsData } from "../../../../util/data/formatSurveyResultsData";
import { JSONtoCSV } from "../../../../util/data/JSONtoCSV";
import { convertToGeoJSON } from "../../../../util/data/convertToGeoJSON";
import { ETAPContext } from "../Context/ETAPContext";
import SurveyResultstoCSV from "../../../../util/data/SurveyResultstoCSV";

const Chart = styled.div`
  margin-left: ${({ left }) => (left ? "20px" : "0px")};
  padding-left: 30px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;
const Header = styled.div`
  margin-left: 50px;
  padding-top: 20px;
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`;

const Charts = styled.div`
  display: flex;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ChartWrapper = styled.div`
  width: 90%;
`;

export const RowS = styled.div`
  display: flex;
  align-items: center;

  // border: 1px solid black;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
export const RowSTable = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.blue};
  border-bottom: 1px solid ${colors.white};
  max-width: unset;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

interface ProjectTableEntity extends ProjectsEntity {
  itemCount: number;
  logCount: number;
}

const SurveyResults = ({
  projectsData,
  itemsData,
  listImage,
  isPublic,
}: {
  listImage: string;
  projectsData: ProjectsEntity[];
  itemsData: any[];
  isPublic: boolean;
}) => {
  // surveyResultsCleaned();
  const [graph2, setGraph2] = useState<PieChartDataType>();
  const [bar3, setBar3] = useState<BarChartType>();
  const [bar5, setBar5] = useState<BarChartType>();

  const groupedProjects = _.groupBy(itemsData, "project_id");
  const itemsByProjectId = Object.entries(groupedProjects);

  const surveyResultsCleanedBar = (surveyResults, q) => {
    if (surveyResults !== undefined) {
      const data: BarChartType = [];
      const flatData = surveyResults.map((survey) => survey.results_json);

      const aggregatedMaterials = _.groupBy(flatData, (ans) => {
        return ans[q];
      });

      Object.keys(aggregatedMaterials).map((key) => {
        if (key) {
          data.push({ name: key, data: aggregatedMaterials[key].length });
        }
      });

      return data;
    }
  };

  const surveyResultsCleanedPie = (surveyResults, q) => {
    if (surveyResults !== undefined) {
      const data: PieChartDataType = [];
      const flatData = surveyResults.map((survey) => survey.results_json);

      const aggregatedMaterials = _.groupBy(flatData, (ans) => {
        return ans[q];
      });

      Object.keys(aggregatedMaterials).map((key) => {
        if (key) {
          data.push({ name: key, y: aggregatedMaterials[key].length });
        }
      });

      return data;
    }
  };

  const { surveyResults } = useListDashboardData("153");
  useEffect(() => {
    let pieData = surveyResultsCleanedPie(surveyResults, 1);
    let barData = surveyResultsCleanedBar(surveyResults, 1);
    setGraph2(pieData);
    barData = surveyResultsCleanedBar(surveyResults, 2);
    setBar3(barData);
    barData = surveyResultsCleanedBar(surveyResults, 4);
    setBar5(barData);
  }, [surveyResults]);

  const headers = [
    { name: "Id", label: "id" },
    { name: "Project Name", label: "name" },
    { name: "Verified", label: "verified" },
  ];

  return (
    <TableContainer>
      {surveyResults && (
        <RowS>
          <Header>
            <h2>Survey Results ({surveyResults.length})</h2>
          </Header>
        </RowS>
      )}
      <Charts>
        <Chart left={true}>
          <h3>Q2. Where are you located?</h3>
          <ChartWrapper>
            {graph2 && graph2.length > 0 ? (
              <PieChart data={graph2} />
            ) : (
              <div>Loading...</div>
            )}
          </ChartWrapper>
        </Chart>
        <Chart left={true}>
          <h3>Q3. How are you funded?</h3>
          <ChartWrapper>
            {bar3 && bar3.length > 0 ? (
              <BarChart data={bar3} />
            ) : (
              <div>Loading...</div>
            )}
          </ChartWrapper>
        </Chart>
      </Charts>
      {projectsData && (
        <SurveyTable
          listImage={listImage}
          projectsData={projectsData}
          itemsData={itemsData}
          isPublic={true}
        />
      )}
    </TableContainer>
  );
};

export default SurveyResults;
