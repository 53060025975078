import React from "react";
import { ProjectsEntity } from "../../../../types/list";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import {
  TableContainer,
  TableTitle,
  TableTitleRow,
} from "../../../../styles/TableComponents";
import { IconButton, TableFooter, TablePagination } from "@material-ui/core";
import { colors } from "../../../../styles/colors";
import numberWithCommas from "../../../../util/numberWithCommas";
import { useRef } from "react";
import { useState } from "react";
import { useListDashboardData } from "../../../../util/hooks/useListDashboardData";
import { SurveyData } from "../../../../types/survey";
import styled from "styled-components";
import { formatSurveyResultsData } from "../../../../util/data/formatSurveyResultsData";
import { JSONtoCSV } from "../../../../util/data/JSONtoCSV";
import { convertToGeoJSON } from "../../../../util/data/convertToGeoJSON";
import SurveyResultstoCSV from "../../../../util/data/SurveyResultstoCSV";
interface ProjectTableEntity extends ProjectsEntity {
  result: SurveyData;
}

const SurveyTable = ({
  projectsData,
  itemsData,
  listImage,
  isPublic,
}: {
  listImage: string;
  projectsData: ProjectsEntity[];
  itemsData: any[];
  isPublic: boolean;
}) => {
  const { surveyResults } = useListDashboardData("153");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const groupedProjects = _.groupBy(itemsData, "project_id");
  const itemsByProjectId = Object.entries(groupedProjects);

  const logsByProject = itemsByProjectId.map((value) => {
    const groupedByLog = _.groupBy(value[1], "log_index");
    return [value[0], Object.keys(groupedByLog).length];
  });

  const headers = [
    { name: "Q1: Organization Name", label: "0" },
    { name: "Q2: Where are you located?", label: "1" },
    { name: "Q3: How are you funded?", label: "2" },
    { name: 'Q4: Specify "other" funding?', label: "3" },
    { name: "Q5: Cleanup Type", label: "4" },
    { name: "Q6: Other General Observations", label: "5" },
  ];

  const ProjectsTableRow = ({ result }: { result: SurveyData }) => {
    const linkRef = useRef<any>(undefined);

    // const [projectData, setProjectData] = useState<ProjectTableEntity>(project);

    // console.log("survey", result);

    return (
      <TableRow>
        {/* <TableCell>{result["user_id"]}</TableCell> */}
        {headers.map((header, i) => (
          <TableCell key={`header_${i}`} align="center">
            {result["results_json"][parseInt(header.label, 10)]}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const linkRef = useRef<any>(undefined);
  //download survey
  const DownloadButton = () => {
    const ButtonWrapper = styled.div`
      padding: 10px 24px; /* Some padding */
      cursor: pointer; /* Pointer/hand icon */
      float: left; /* Float the buttons side by side */
      margin-left: 1rem;
      font-size: 12pt;
      color: black;
      border: 1px solid #bfd4e3;
      background: rgb(245, 249, 255, 0.5);
    `;

    let geojson = convertToGeoJSON(surveyResults);

    let test = JSON.stringify(geojson);

    // console.log("test", test)

    const handleDownload = () => {
      // compute data and convert to csv
      const newDownloadData = SurveyResultstoCSV(
        formatSurveyResultsData(surveyResults)
      );

      const csvData = JSONtoCSV(newDownloadData);

      // store data in new blob and create a url
      const csvBlob = new Blob([csvData], { type: "text/csv" });
      const url = URL.createObjectURL(csvBlob);

      // set <a> tags href to blob url
      if (linkRef.current) {
        linkRef.current.href = url;
      }
      // click the tag
      linkRef.current && linkRef.current.click();
    };

    return (
      <>
        <ButtonWrapper onClick={handleDownload}>
          Download All Survey Data
        </ButtonWrapper>
        <a target="_blank" download={"survey-data.csv"} ref={linkRef}></a>
      </>
    );
  };

  const ProjectsTableTitle = () => {
    return (
      <TableTitleRow altFont={true}>
        Answers {surveyResults && <DownloadButton />}
      </TableTitleRow>
    );
  };

  const cellStyle = { color: colors.white };

  //add computed aggregates to project object
  projectsData.forEach((project) => {
    let projectItemCount: number;
    let projectLogCount: number | string;

    if (itemsByProjectId.length > 0) {
      const filtered = itemsByProjectId.filter((item) => {
        if (item[0] === "null" && project.name === "Default") {
          return true;
        }
        return item[0] === project.id;
      });
      if (filtered.length > 0) {
        projectItemCount = filtered.reduce(
          (prev, curr) => (curr[1].length += prev),
          0
        );
      }
    }

    if (logsByProject.length > 0) {
      const filtered = logsByProject.filter((item) => {
        if (item[0] === "null" && project.name === "Default") {
          return true;
        }
        return item[0] === project.id;
      });
      if (filtered.length > 0) {
        projectLogCount = filtered.reduce((prev, curr) => {
          if (typeof curr[1] === "number") {
            return (prev += curr[1]);
          } else if (typeof curr[1] === "string") {
            return (prev += parseInt(curr[1], 10));
          }
        }, 0);
      }
    }

    project["itemCount"] = projectItemCount || 0;
    project["logCount"] = projectLogCount || 0;
  });

  const emptyItemsRows =
    rowsPerPage -
    Math.min(rowsPerPage, projectsData.length - page * rowsPerPage);

  return (
    <TableContainer>
      <ProjectsTableTitle />
      <Table>
        <TableHead style={{ backgroundColor: colors.blue }}>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell key={`${i}`} align="center" style={cellStyle}>
                {header.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {surveyResults
            ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((SurveyResult: SurveyData, i) => (
              <ProjectsTableRow
                key={`project_row_${i}`}
                result={SurveyResult}
              />
            ))}
          {emptyItemsRows > 0 && (
            <TableRow style={{ height: 33 * emptyItemsRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              page={page}
              colSpan={6}
              count={projectsData.length}
              rowsPerPage={rowsPerPage}
              onChangePage={(
                _e: any,
                newPage: React.SetStateAction<number>
              ) => {
                setPage(newPage);
              }}
              onChangeRowsPerPage={(e: { target: { value: string } }) => {
                setRowsPerPage(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default SurveyTable;
